import img4 from '../../img/VCMS/img4.png'; 
import img5 from '../../img/VCMS/img5.png'; 
import img6 from '../../img/VCMS/img6.png'; 
import img7 from '../../img/VCMS/img7.png'; 


const InvestArray = [
    {
        img: img4,
        title: 'Cryptocurrency Tracing and Analysis',
        desc: 'Advanced blockchain analytics track cross-chain transactions, linking wallets to real-world identities through metadata, transaction signatures, and integration with global KYC/AML databases. This enables seamless multi-currency tracing and accurate owner identification.',
    },
    {
        img: img5,
        title: 'Geospatial Intelligence Integration',
        desc: 'Our platform integrates financial transaction data with geospatial intelligence, enabling real-time and historical mapping of criminal activities. This fusion enhances investigative reach by connecting blockchain data to physical movements',
    },
    {
        img: img6,
        title: 'Advanced Knowledge Graph Technology',
        desc: 'A dynamic knowledge graph enables infinite exploration of global financial ecosystems, revealing hidden connections through multi-dimensional representations and link analysis across different blockchain networks.',
    },
    {
        img: img7,
        title: 'Advanced Knowledge Graph Technology',
        desc: 'AI-driven predictive analytics identify emerging financial crime trends, allowing proactive strategies through anomaly detection, trend analysis, and machine learning models trained on his',
    },
]

export default InvestArray