import React from 'react'
import { AttentionSeeker, Fade } from 'react-awesome-reveal';

function Introduction() {
  return (
    <div className="container mt-5">
      <Fade direction='up' >
        <div className="center-div">
          <h2 className="vcms-heading w-75" style={{ fontSize: '36px', textAlign: 'center' }}>
              Introducing the Valoores Crypto Management System (VCMS):
              A beacon of innovation in the world of virtual assets
          </h2>
          </div>
      </Fade>
      <Fade direction='up' delay={400}>
        <p className="vcms-paragraph">
          VALOORES transforms investigations and compliance in the growing cryptocurrency landscape. Acting like a skilled detective in the digital realm, it provides clarity for navigating the blockchain and identifying hidden risks.<br />
          Powered with geospatial intelligence and advanced analytics, it not only tracks illicit activities but also anticipates future threats.<br />
          VCMS system enhances digital asset management through robust risk assessment and monitoring, integrating top-tier security and risk mitigation to protect operations from emerging challenges.
        </p>
      </Fade>
      <Fade direction='up' delay={600} cascade>
        <p className="vcms-paragraph">
          With VALOORES, you're not just ahead; you're leading the charge in the next era of crypto investigations platforms:
        </p>
      <ul className="vcms-list">
        <li>Unparalleled visibility into crypto transactions</li>
        <li>Real-time mapping of global financial ecosystems</li>
        <li>Predictive insights to stay ahead of criminal networks</li>
        <li>Seamless integration of blockchain data with real-world intelligence</li>
      </ul>
      </Fade>
    </div>
  )
}

export default Introduction