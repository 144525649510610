import React from 'react'
import { Zoom } from 'react-awesome-reveal'

function carouselblog() {
  return (
 <div className="vcms-background d-flex align-items-center justify-content-center">
    <div className=" vcms-title">
      <Zoom>
        <h1>Building Security Foundations for Digital Assets</h1>
      </Zoom>
    </div>
  </div>
  )
}

export default carouselblog