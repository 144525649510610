import React from 'react';
import IMAG1 from '../../../img/VCMS/IMAG1.png';
import IMAG2 from '../../../img/VCMS/IMAG2.png';
import IMAG3 from '../../../img/VCMS/IMAG3.png';
import IMAG4 from '../../../img/VCMS/IMAG4.png';
import IMAG5 from '../../../img/VCMS/IMAG5.png';
import IMAG6 from '../../../img/VCMS/IMAG6.png';
import IMAG7 from '../../../img/VCMS/IMAG7.png';
import IMAG8 from '../../../img/VCMS/IMAG8.png';
import IMAG9 from '../../../img/VCMS/IMAG9.png';
import { Fade } from 'react-awesome-reveal';

function ComplianceAspects() {
  return (
    <div className="container">
      <div className="center-div">
        <h2 className="vcms-heading w-75" style={{ fontSize: '36px', textAlign: 'center' }}>
          <Fade direction="up">
            Compliance Aspects - Proactive Regulatory Strategies
          </Fade>
        </h2>
      </div>

      <div className="container mt-5">
        <div className="row">
          {/* Left Column */}
          <div className="col-md-6 d-flex flex-column">
            <Fade direction="up" triggerOnce>
              <p style={{ textDecoration: 'underline' }}>
                Document Authenticity & Wallet Address Verification
              </p>
              <p>
                Cryptographic document validation combined with geo-smart wallet address verification ensures accurate, up-to-date compliance and reduces fraud risks.
              </p>

              <p style={{ textDecoration: 'underline' }}>Identity Verification for Cryptocurrency Platforms Users</p>
              <p>Advanced biometric verification (Face ID, fingerprint recognition) prevents fraudulent activity by ensuring accurate user identity checks in line with KYC/AML regulations.</p>

              <p style={{ textDecoration: 'underline' }}>Comprehensive Risk Assessment for Crypto Transactions</p>
              <p>Our AI-powered risk matrix evaluates transaction risk using real-time monitoring, wallet matching, and behavior analysis to flag suspicious activity and link it to potential crimes.</p>

              <p style={{ textDecoration: 'underline' }}>Blockchain Network Transaction Monitoring</p>
              <p>Real-time monitoring across multiple blockchain networks, utilizing geospatial data and link analysis, enables detection of high-risk behaviors like rapid cross-chain transactions and wallet tumbling.</p>

              <p style={{ textDecoration: 'underline' }}>Detection of Suspicious or Concealed Transaction Patterns</p>
              <p>Dynamic rule-based alerts detect illicit activity, including money laundering and fraud, using machine learning models that adapt to evolving criminal tactics.</p>

              <p style={{ textDecoration: 'underline' }}>Regulatory Compliance & FATF Travel Rule Integration</p>
              <p>Full integration with FATF Travel Rule ensures secure data sharing between VASPs, with automated record-keeping for regulatory audits and reporting, ensuring compliance with global standards.</p>
            </Fade>
          </div>

          {/* Right Column */}
            <div className="col-md-6">
              <Fade triggerOnce>
            <div
            className='my-2'
              style={{
                backgroundImage: `url(${IMAG1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '300px',
                // marginBottom: '10px',
              }}
            ></div>

            <div className="d-flex gap-2">
              <div
                style={{
                  backgroundImage: `url(${IMAG3})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center bottom',
                  height: '150px',
                  width: '50%',
                  // marginRight: '5px',
                }}
              ></div>
              <div
                style={{
                  backgroundImage: `url(${IMAG2})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '150px',
                  width: '50%',
                  // marginLeft: '5px',
                }}
              ></div>
            </div>

            <div
            className='my-2'
              style={{
                backgroundImage: `url(${IMAG4})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '300px',
                // marginBottom: '10px',
              }}
            ></div>

            <div
              className='my-2'
              style={{
                backgroundImage: `url(${IMAG5})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '300px',
                // marginBottom: '10px',
              }}
            ></div>

            <div className="d-flex gap-2">
              <div
                style={{
                  backgroundImage: `url(${IMAG6})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '300px',
                  width: '50%',
                  // marginRight: '5px',
                }}
              ></div>
              <div
                style={{
                  backgroundImage: `url(${IMAG7})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '300px',
                  width: '50%',
                  // marginLeft: '5px',
                }}
              ></div>
            </div>

            <div
              className='my-2'
              style={{
                backgroundImage: `url(${IMAG8})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center top',
                height: '300px',
                // marginBottom: '10px',
              }}
            ></div>

            <div
              className='my-2'
              style={{
                backgroundImage: `url(${IMAG9})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '300px',
              }}
            ></div>
             </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComplianceAspects;
