import React from 'react';
import './ServicesGroup.css';
import VFDS0 from '../../img/vfds/VFDS0.png';
import { Zoom } from 'react-awesome-reveal';

const Banner = () => {
  return (
    <div
      className="services-container d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: `url(${VFDS0})`,
        height: '650px',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="background-overlay"></div>
      <h1 className="text-white text-center w-50" style={{ fontSize: '3rem' }}>
      <Zoom>
        The Fusion of Technology & Data for Financial Synergy
        </Zoom>
      </h1>
    </div>
  );
};

export default Banner;
