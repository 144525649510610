import React from 'react'
import img1 from '../../../img/VCMS/img1.png';
import img3 from '../../../img/VCMS/img3.png';
import { Fade } from 'react-awesome-reveal';
function THreeImg() {
  return (
  <div className="container mt-5">
    <Fade direction="up" cascade>
      <div className="d-flex justify-content-center gap-4">
        <div className="vcms-image-box" style={{ backgroundImage: `url(${img1})` }}></div>
        <div className="vcms-info-box">
          <h1>Technology & Innovation to Drive the Next Era of Compliance</h1>
        </div>
        <div className="vcms-image-box" style={{ backgroundImage: `url(${img3})` }}></div>
      </div>
    </Fade>
  </div>
  )
}

export default THreeImg