import React from 'react'
import CEOBanner from '../../img/banners/leader.jpg'
import Vlogo from '../../img/full_logoC.png'

const Ceo = () => {
  return (
    <>
        <div className='letter__container d-flex flex-row align-items-center justify-content-center' style={{backgroundImage: `url(${CEOBanner})`, height: '500px', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <div className="overlay"></div>
            <div className='position-relative z-2'>
                <h1 className='text-white display-3'>Letter Of The CEO</h1>
            </div>
        </div>
        <div className="letter_body container my-5 w-75">
            <div className="letter_body-logo">
                <img src={Vlogo} alt="" width='350' />
            </div>
            <div className='my-5'>
                <h2 className='w-100 letter_color display-5 fst-italic fw-letter'>VALOORES, More than 30 Years in 30 Seconds Over Three Decades of Innovation in Enterprise Solutions </h2>
                <h4 className='my-5 letter_color' style={{lineHeight: '2.5rem'}}>For more than 30 years, VALOORES has been at the forefront of delivering advanced enterprise solutions across multiple industries, specializing in Cybersecurity, Business Intelligence (BI), and Analytics. Our cutting-edge highly secure cloud services, underpins our commitment to excellence in protecting data and facilitating informed decision-making.</h4>
            </div>
            <div className='my-3 '>
                <h2 className='fw-normal w-100 letter_colorfst-italic fw-letter'>VFDS - Integrated Solutions for Banks, Insurance & Financial Institutions </h2>
                <h4 className='letter_color' style={{lineHeight: '2.5rem'}}>We offer a comprehensive suite of solutions tailored to address the financial sector’s complex challenges, from daily operations to year-end financial reporting. Our business intelligence tools have evolved from being an added advantage to a core necessity for organizations aiming to stay competitive and compliant.</h4>
            </div>
            <div className='my-5 '>
                <h2 className='fw-normal w-100 letter_colorfst-italic fw-letter'>VCIS - Counter-Intelligence & Financial Crime Prevention</h2>
                <h4 className='letter_color' style={{lineHeight: '2.5rem'}}>Our advanced investigative technology provides effective features to safeguard national security, aiding law enforcement agencies in the fight, detection and prevention. It is also an effective defense against fraud, corruption, and financial crime. We deliver robust capabilities for conducting investigations and optimizing compliance activities.</h4>
            </div>
            <div className='my-5 '>
                <h2 className='fw-normal w-100 letter_colorfst-italic fw-letter'>Geospatial Location Solutions</h2>
                <h4 className='letter_color' style={{lineHeight: '2.5rem'}}>The innovative crowd intelligence technology aids in the combat of terrorism and prevention of crimes. Our approach to geospatial data offers a transformative way for informed decision making for the public and private sectors. </h4>
            </div>
            <div className='my-5 '>
                <h2 className='fw-normal w-100 letter_colorfst-italic fw-letter'>Retail & Supply Chain</h2>
                <h4 className='letter_color' style={{lineHeight: '2.5rem'}}>With over three decades of experience in the retail sector, VALOORES understands the unique challenges faced by retailers. From fragmented operations and supplier negotiations to navigating competitive markets, our solutions streamline processes, enhance operational efficiency, and drive profitability.</h4>
            </div>
            <div className='my-5 '>
                <h2 className='fw-normal w-100 letter_colorfst-italic fw-letter'>Multimedia & Education</h2>
                <h4 className='letter_color' style={{lineHeight: '2.5rem'}}>As pioneers in digital transformation, we bring a holistic approach to multimedia and interactive communication. Our continuum of services ensures seamless integration of innovative solutions across education and media sectors, enabling dynamic learning environments and effective communication strategies.</h4>
            </div>
            <div className='my-5 '>
                <h4 className='letter_color' style={{lineHeight: '2.5rem'}}>VALOORES continues to set the standard for innovation, security, and operational excellence across industries, helping businesses navigate today’s complex challenges with confidence.
                </h4>
            </div>
        </div>
    </>
  )
}

export default Ceo