import React from 'react'
import './Fatf.css'
import VCISLogo from '../../img/vcms_white.png'
import Fatf1 from '../../img/Fatf/carousell.jpg'
import Fatf2 from '../../img/Fatf/1.jpg'
import Fatf3 from '../../img/Fatf/2.jpg'
import Fatf4 from '../../img/Fatf/3.jpg'
import Fatf5 from '../../img/Fatf/4.jpg'
import Fatf6 from '../../img/Fatf/5.jpg'
import {Fade} from 'react-awesome-reveal';
import Zoom from 'react-awesome-reveal';

const Fatf = () => {
    const FatfArray = [
        {img: Fatf2, desc: (
            <>
                <h1 className='mb-3'>VCMS: Revolutionizing FATF Compliance for the Digital Asset Era</h1>
                <h5 >As cryptocurrencies reshape the financial landscape, organizations face mounting regulatory challenges to ensure compliance with the Financial Action Task Force (FATF) standards. VALOORES introduces the <strong>Virtual Compliance Management System (VCMS)</strong>, an enterprise-grade solution designed to address the complex requirements of FATF compliance, providing seamless alignment with Anti-Money Laundering (AML) and Counter-Terrorism Financing (CFT) frameworks.</h5>
            </>
        )},
        {img: Fatf4, desc: (
            <>
                <h1 className='mb-3'>Comprehensive FATF Compliance Features</h1>
                <h5 >VCMS delivers a suite of capabilities tailored to ensure full adherence to FATF guidelines, empowering both Virtual Asset Service Providers (VASPs) and law enforcement agencies with advanced tools.</h5>
                <p>
                    <strong>1. Digital KYC and Identity Verification</strong>
                    VCMS incorporates advanced biometric technology to securely authenticate users, ensuring compliance with global standards. The platform also uses geospatial verification, adding a critical layer of security by cross-referencing location data during identity checks. Its automated monitoring capabilities continuously track high-value or geographically risky transactions, flagging anomalies to enhance compliance protocols.
                </p>
                <p>
                    <strong>2. FATF Travel Rule Compliance</strong>
                    The system ensures transparency in cryptocurrency transactions by implementing the FATF Travel Rule. It securely captures and transmits data, such as the identities of senders and receivers, for transactions exceeding the specified thresholds. This approach mitigates risks associated with money laundering and terrorist financing by increasing transaction traceability.
                </p>
                <p>
                    <strong>3. Dynamic Risk-Based Authentication (CRBA)</strong>
                    VCMS integrates cryptocurrency transactions into a sophisticated risk matrix, enabling the detection of high-risk activities. The system identifies unusual transaction patterns, connections to flagged wallet addresses, and other red flags, allowing organizations to proactively mitigate risks in real time.
                </p>
                <p>
                    <strong>4. Real-Time Transaction Monitoring</strong>
                    With VCMS, institutions gain the ability to oversee transactions with unparalleled precision. The system detects patterns like repetitive amounts, rapid multi-location transfers, and irregular exchange activities. This comprehensive monitoring ensures that suspicious behaviors are quickly identified and addressed.
                </p>
            </>
        )},
        {img: Fatf3, desc: (
            <>
                <h1 className='mb-3'>Investigative Excellence for Financial Crime</h1>
                <h5 >VCMS equips law enforcement agencies with powerful investigative tools to combat financial crime. Its dynamic knowledge graphs provide a visual representation of fund flows across wallets and exchanges, enabling efficient tracking and detection of illicit activities. Geospatial analytics link wallet addresses to specific owners and detect unauthorized actions through AI-driven insights. The system’s anomaly detection further enhances security by flagging irregular behaviors, such as unexpected location changes, ensuring a proactive response to potential threats.</h5>
            </>
        )},
        {img: Fatf5, desc: (
            <>
                <h1 className='mb-3'>Why VCMS?</h1>
                <h5 >VCMS combines global visibility with comprehensive data integration, leveraging diverse sources such as telecom and tracking device data to create a holistic view of financial activities. Its offline accessibility ensures that investigations remain secure and isolated from external networks. By integrating cutting-edge AI, Big Data, and temporal analysis, VCMS delivers future-proof solutions that not only address current compliance needs but also anticipate emerging threats.</h5>
            </>
        )},
        {img: Fatf6, desc: (
            <>
                <h1 className='mb-3'>Empowering the Future of Compliance</h1>
                <h5 >VCMS equips institutions to thrive in a regulated digital asset ecosystem while maintaining transparency, security, and operational efficiency. As the financial landscape evolves, VCMS remains a cornerstone for safeguarding the integrity of crypto-financial systems, ensuring compliance with FATF standards, and fostering trust across global markets.</h5>
            </>
        )},
    ]
  return (
    <>
        <div className='fatf__container position-relative' style={{backgroundImage: `url(${Fatf1})`, backgroundPosition: 'center center', backgroundSize: 'cover', height: '500px'}}>
            <div className="fatf_overlay"></div>
            <div className="fatf__content w-50 text-center position-relative" style={{zIndex: '5'}}>
                <img src={VCISLogo} className='mb-3' style={{width: '35%'}} alt="" />
                <h1 className='text-white'>Your ultimate enterprise solution for seamless FATF compliance, empowering AML and CFT alignment with unmatched precision and innovation. </h1>
            </div>
        </div>
        <div className="px-5 my-5">
            {FatfArray.map((data,index) => {
                return (
                    <div key={index} className='row mb-5'>
                            <div className={` col-md-6 ${index %2 === 0 ? 'order-1' : 'order-2'}`}>
                                {index === 1 
                                ?
                                <Fade cascade>
                                    <div style={{backgroundImage: `url(${data.img})`, backgroundPosition: 'center center', backgroundSize: 'cover', height: '850px', backgroundRepeat: 'no-repeat'}}></div> 
                                    
                                </Fade>
                                :
                                <Fade cascade>
                                    <div style={{backgroundImage: `url(${data.img})`, backgroundPosition: 'center center', backgroundSize: 'cover', height: '500px', backgroundRepeat: 'no-repeat'}}></div>
                                </Fade>
                                    }
                                {/* <img src={data.img} className='w-100' alt="" /> */}
                            </div>
                        <div className={` col-md-6 d-flex align-items-start justify-content-center flex-column ${index %2 === 0 ? 'order-2' : 'order-1'}`}>
                            <Fade up>
                                {data.desc}
                            </Fade>
                        </div>
                    </div>
                )
            })}
        </div>
    </>
  )
}

export default Fatf