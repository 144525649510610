const JobsArray = 
[
    {
        category:'Engineering And Design',
        title:'DevOps Engineer',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Junior / Intermediate',
    },
    {
        category:'Engineering And Design',
        title:'Software Engineer',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Junior / Intermediate',
    },
    {
        category:'Engineering And Design',
        title:'Data Analytics Lead',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Senior',
    },
    {
        category:'Engineering And Design',
        title:'Data Scientist',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Junior / Intermediate',
    },
    {
        category:'Engineering And Design',
        title:'Quality Assurance Engineer',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Senior',
    },
    {
        category:'Engineering And Design',  
        title:'Machine Learning Engineer',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Junior / Intermediate',
    },
    {
        category:'Business Development',
        title:'Business Development Regional - North America',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Senior',
    },
    {
        category:'Business Development',
        title:'Business Development Regional - Europe',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Senior',
    },
    {
        category:'Business Development',
        title:'Business Development Regional - GCC',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Senior',
    },
    {
        category:'Product Management',
        title:'Project Manager',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Senior',
    },
    {
        category:'Product Management',
        title:'Business Analyst',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Junior',
    },
    {
        category:'Product Management',
        title:'Business Analyst',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Senior',
    },
    {
        category:'Information Technology',
        title:'Web Designer',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Senior',
    },
    {
        category:'Information Technology',
        title:'Database Administrator(DBA)',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'Cyber Security Specialist',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'Oracle Developer',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'Weblogic / Websphere / Tomcat Administrator',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'Web Developer',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'Database Integrator',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'IT',
        desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        order: '',
        level: 'Junior / Intermediate',
    },
]

export default JobsArray