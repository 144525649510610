import React from 'react';
import {Link} from 'react-router-dom';
import AML from '../../img/services/aml.jpg';
import service2 from '../../img/Service2.jpg';
import service3 from '../../img/Service3.jpg';
import service4 from '../../img/Service4.jpg';
import Vcms from '../../img/vcms_white.png'
import Vrsc from '../../img/VRCS-white.png'
import Vfds from '../../img/VFDS-white.png'
import Vtech from '../../img/Vtech-white.png'

const Services = () => {
  const service = [
    {
      image: AML,
      titleImg: Vfds,
      title: Vfds,
      description: 'data processing platform to integrate diverse data streams, enhance compliance with AI-driven analytics, and ensure top-tier information security across all solutions... Read More →',
      link: '/VFDS'
    },
    {
      image: service2,
      titleImg: Vcms,
      title: 'Fraud Management',
      description: 'system enhances digital asset management through robust risk assessment and monitoring, integrating top-tier security and risk mitigation to protect operations from emerging challenges.... Read More →',
      link: '/VCMS'
    },
    {
      image: service3,
      titleImg: Vtech,
      title: 'Regulatory',
      description: 'Bridging physical and digital worlds, unlocking insights, empowering decisions... Read More →',
      link: '/banking/riskandcompliance?variable=regulatoryreportingsolution'
    },
    {
      image: service4,
      titleImg: Vrsc,
      title: 'Payment',
      description: '',
      link: '/banking/feature?page=financialservices'
    },
  ];
  return (
    <>
    <div className="services_section1" id='services_scroll1'>
        <div className="container">
            <div className="row">
                <div className="services__title">
                    <h2><span>VALOORES: Systems </span>That Empower Your Every Move!</h2>
                </div>
                {service.map((serv, index) => (
                <Link target="_blank"  key={index} to={serv.link} className='col-md-6 p-3'>
                  <div className="">
                    <div className="img_bg" style={{ backgroundImage: `url(${serv.image})`}}>
                      <div className="overlay__bg"></div>
                      <div className="services__content gap-3">
                        <img src={serv.titleImg} alt=""/>
                        <h5 className='text-white mt-3 text-capitalize'>{serv.description}</h5>
                      </div>
                    </div>
                  </div>
                </Link>
                ))}
            </div>
        </div>
    </div>
    </>
  )
}

export default Services